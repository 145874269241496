<template>
    <div class="box">
        
        <div  class="main">
            <div class="header">
                {{currentYMD}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;阴历：{{currentYMDCN}}
            </div>
            <div class="nav">
                <div class="nav-box">
                
                    <template v-for="(item, i) in navList">
                        <div v-if="item.level == '1'" @click="tabsClicklevel1(item)"  :key="i" class="nav-level cp level1">
                            <span class="">{{item.name}}</span>
                            <img class="right" v-if="item.checked" src="../../assets/images/dataScreen/right.png" alt="">
                        </div>
                        <div v-if="item.level == '2'" @click="tabsClick(item)" :key="i" class="nav-level level2">
                            <img class="nav-icon mr37" :src="item.icon" alt="">
                            <span class="level-text-2">{{item.name}}</span>
                            <img class="right" v-if="item.checked" src="../../assets/images/dataScreen/right.png" alt="">
                        </div>
                    </template>
                </div>
            </div>
            <div class="content flex">
                <div class="nav-zw"></div>
                <DataScreen1 @tabsTypeClick="tabsTypeClick" v-if="tabsType == '1'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen1>
                <DataScreen2 v-if="tabsType == '2'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen2>
                <DataScreen3 v-if="tabsType == '3'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen3>
                <DataScreen4 v-if="tabsType == '4'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen4>
                <DataScreen5 v-if="tabsType == '5'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen5>
                <DataScreen6 v-if="tabsType == '6'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen6>
                <DataScreen7 v-if="tabsType == '7'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen7>
                <DataScreen8 v-if="tabsType == '8'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen8>
                <DataScreen9 v-if="tabsType == '9'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen9>
                <DataScreen10 v-if="tabsType == '10'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen10>
                <DataScreen11 v-if="tabsType == '11'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen11>
                <DataScreen12 v-if="tabsType == '12'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen12>
                <DataScreen13 v-if="tabsType == '13'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen13>
                <DataScreen14 v-if="tabsType == '14'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen14>
                <DataScreen15 v-if="tabsType == '15'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen15>
                <DataScreen16 v-if="tabsType == '16'" class="content-right flex1 animate__animated animate__fadeIn"></DataScreen16>
            </div>
        </div>
    </div>
</template>

<script>
import calendar from '../../utils/calendar'
import DataScreen1 from "./components/dataScreen1"
import DataScreen2 from "./components/dataScreen2"
import DataScreen3 from "./components/dataScreen3"
import DataScreen4 from "./components/dataScreen4"
import DataScreen5 from "./components/dataScreen5"
import DataScreen6 from "./components/dataScreen6"
import DataScreen7 from "./components/dataScreen7"
import DataScreen8 from "./components/dataScreen8"
import DataScreen9 from "./components/dataScreen9"
import DataScreen10 from "./components/dataScreen10"
import DataScreen11 from "./components/dataScreen11"
import DataScreen12 from "./components/dataScreen12"
import DataScreen13 from "./components/dataScreen13"
import DataScreen14 from "./components/dataScreen14"

import DataScreen15 from "./components/dataScreen15"
import DataScreen16 from "./components/dataScreen16"
import 'animate.css';

export default {
    name: '',
    data(){
        return{
            tabsType: this.$route.query.tabsType || '3',
            days: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
            curDayArr: ["1", "2", "3", "4", "5", "6", "7", "8"],
            currentYMD: '',
            currentYMDCN: '',
            navList: [{
                name: '种植产业',
                checked: false,
                level: '1',
                subName: '一产',
                tabsType: '',
            },
            // {
            //     name: '玉米及饲草',
            //     checked: false,
            //     level: '2',
            //     icon: require('../../assets/images/dataScreen/icon1.png'),
            //     tabsType: '',
            // },
            {
                name: '马铃薯',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon2.png'),
                tabsType: '3',
            },{
                name: '大漠蔬菜',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon3.png'),
                tabsType: '13',
            },
            // {
            //     name: '林果',
            //     checked: false,
            //     level: '2',
            //     icon: require('../../assets/images/dataScreen/icon4.png'),
            //     tabsType: '',
            // },
            {
                name: '谷物杂粮',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon5.png'),
                tabsType: '16',
            },
            // {
            //     name: '其他',
            //     checked: false,
            //     level: '2',
            //     icon: require('../../assets/images/dataScreen/icon6.png'),
            //     tabsType: '',
            // },
            {
                name: '养殖产业',
                checked: false,
                level: '1',
                tabsType: '',
            },{
                name: '肉羊',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon7.png'),
                tabsType: '1',
            },{
                name: '肉牛',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon8.png'),
                tabsType: '8',
            },
            // {
            //     name: '猪',
            //     checked: false,
            //     level: '2',
            //     icon: require('../../assets/images/dataScreen/icon9.png'),
            //     tabsType: '',
            // },
            {
                name: '家禽',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon10.png'),
                tabsType: '9',
            },
            // {
            //     name: '水产',
            //     checked: false,
            //     level: '2',
            //     icon: require('../../assets/images/dataScreen/icon11.png'),
            //     tabsType: '',
            // },
            {
                name: '加工产业',
                checked: false,
                level: '1',
                tabsType: '15',
            },{
                name: '服务业',
                checked: false,
                level: '1',
                tabsType: '',
            },{
                name: '社会化服务',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon12.png'),
                tabsType: '14',
            },{
                name: '共享农机',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon13.png'),
                tabsType: '12',
            },{
                name: '仓储冷链',
                checked: false,
                level: '2',
                icon: require('../../assets/images/dataScreen/icon14.png'),
                tabsType: '10',
            },]
            
        }
    },

    mounted(){
        this.initCurrentYMD();
        this.flag()
    },
    components: {
        DataScreen1,
        DataScreen2,
        DataScreen3,
        DataScreen4,
        DataScreen5,
        DataScreen6,
        DataScreen7,
        DataScreen8,
        DataScreen9,
        DataScreen10,
        DataScreen11,
        DataScreen12,
        DataScreen13,
        DataScreen14,
        DataScreen15,
        DataScreen16
    },
    methods:{
        tabsTypeClick(e) {
            this.tabsType = e
        },
        tabsTypeClickById(e, id) {

            this.tabsType = e;
            
        },
        flag() {
            for(var i=0; i<this.navList.length; i++) {
                this.navList[i].checked = false;
                if(this.navList[i].tabsType == this.tabsType) {
                    this.navList[i].checked = true;
                }
                if(this.tabsType == '3') {
                    if(this.navList[i].tabsType == '2') {
                        this.navList[i].checked = true
                    }
                }
            }
        },
        tabsClick(item){
            if(item.tabsType != '') {
                this.tabsType = item.tabsType
                this.flag()
            }
            
            // if(item.name == '肉羊') {
            //     this.tabsType = '1'
            // }
            // if(item.name == '马铃薯') {
            //     this.tabsType = '3'
            // }
            // if(item.name == '共享农机') {
            //     this.tabsType = '12'
            // }
            // if(item.name == '鸡') {
            //     this.tabsType = '9'
            // }
            // if(item.name == '仓储冷链') {
            //     this.tabsType = '10'
            // }
            // if(item.name == '谷物杂粮') {
            //     this.tabsType = '11'
            // }
        },
        tabsClicklevel1(item) {
            for(var i=0; i<this.navList.length; i++) {
                this.navList[i].checked = false;
                if(item.name ==  this.navList[i].name) {
                    this.navList[i].checked = true
                }
            }
            if(item.name == '加工产业') {
                this.tabsType = '15'
            }
        },
        
        initCurrentYMD() {
            let date = new Date();
            let nongliObj = calendar.calendar.solar2lunar(date.getFullYear(), (date.getMonth() + 1), (date.getDate()));
            this.currentYMDCN = (nongliObj.IMonthCn + nongliObj.IDayCn);
            this.curDayArr[0] = (date.getDate()-2); 
            this.curDayArr[1] = (date.getDate()-1); 
            this.curDayArr[2] = (date.getDate()-0); 
            this.curDayArr[3] = (date.getDate()+1); 
            this.curDayArr[4] = (date.getDate()+2); 
            this.curDayArr[5] = (date.getDate()+3); 
            this.curDayArr[6] = (date.getDate()+4); 
            this.curDayArr[7] = (date.getDate()+5); 
            this.currentYMD = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + (date.getDate()) + ' ' + this.days[date.getDay()];
        }
    }
}
</script>

<style scoped>

    .box {
        width: 100%;
        overflow: hidden;
        overflow-x: auto;
    }
    .main {
        width: 15360px;
        height: 3456px;
        background: #0A131F;
        position: relative;
    }
    .header {
        width: 14900px;
        height: 371px;
        line-height: 210px;
        background: url(../../assets/images/dataScreen/title.png) no-repeat;
        background-size: 100% 100%;
        margin: 0 auto 0;
        color: #EEC915;
        font-size: 48px;
        box-sizing: border-box;
        padding-left: 30px;
    }
    .content-right {
        top: 0;
        position: absolute;
        width: calc(100% - 1675px);
        height: 100%;
        left: 1675px;
        box-sizing: border-box;
        padding-top: 371px;
    }
    .content-right-new{
        top: 0;
        position: absolute;
        box-sizing: border-box;
    }
    .nav {
        width: 1460px;
        height: 100%;
        background:  url(../../assets/images/dataScreen/nav-box.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        top: 0;
        left: 212px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        /* padding-top: 325px; */
    }
    .nav-zw {
        width: 1460px;
        margin-left: 212px;
    }
    .nav-box {
        width: 534px;
        margin: 0 auto;
    }

    .nav-level {
        width: 100%;
        margin-bottom: 32px;
        font-size: 54px;
        color: #000;
        position: relative;
    }
    .level1 {
        text-align: center;
        height: 132px;
        line-height: 132px;
        background: url(../../assets/images/dataScreen/nav-1.png) no-repeat;
        background-size: 100% 100%;
        font-weight: 600;
    }
    .level2 img {
        display: inline-block;
    }
    .level2 {
        box-sizing: border-box;
        padding-left: 64px;
        height: 120px;
        line-height: 120px;
        background: url(../../assets/images/dataScreen/nav.png) no-repeat;
        background-size: 100% 100%;
        color: #fff;
        cursor: pointer;
    }
    .level-text-2 {
        opacity: .8;
    }
    .nav-icon {
        width: 64px;
    }
    .right {
        width: 69px;
        height: 79px;
        position: absolute;
        right: -80px;
        top: 30px;
    }
</style>